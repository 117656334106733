import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown, Container, NavbarBrand } from "react-bootstrap";
import avatar from '../img/avatar.jpg'


export default class NavBar extends Component {
  render() {
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky='top'>
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#portfolio">Portfolio</Nav.Link>
           {/*   
              <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                 <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            <NavbarBrand className="navbar-nav brand">
                <img src={avatar} alt="" className="brand-img" />
            </NavbarBrand>
           {/*  <Nav>
              <Nav.Link href="#deets">Resume</Nav.Link>
              <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

 /*        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white" data-spy="affix" data-offset-top="510">
            <div className="container">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                



                <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse mt-sm-20 navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <a href="#home" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="#about" className="nav-link">About</a>
                        </li>
                        <li className="nav-item">
                            <a href="#resume" className="nav-link">Resume</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav brand">
                        <img src={avatar} alt="" className="brand-img" />
                        <li className="brand-txt">
                            <h5 className="brand-title">John Doe</h5>
                            <div className="brand-subtitle">Web Designer | Developer</div>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a href="#portfolio" className="nav-link">Portfolio</a>
                        </li>
                        <li className="nav-item">
                            <a href="#blog" className="nav-link">Blog</a>
                        </li>
                        <li className="nav-item last-item">
                            <a href="#contact" className="nav-link">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </div> */
        
        )
    }
}