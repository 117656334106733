import React, { Component } from 'react'

export default class About extends Component {
    render() {
      return (
        <div className="container-fluid">
    <div id="about" className="row about-section">
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">Who am I ?</h3>
            <span className="line mb-5"></span>
            <h5 className="mb-3">A Passionate Engineering Manager and Developer from Edmonton, Alberta, Canada</h5>
{/*             <p className="mt-20">Lorem ipsum dolor sit amet, consectetur adipisicing elit.sit amet, Qui deserunt consequatur fugit repellendusillo voluptas?</p>
            <button className="btn btn-outline-danger"><i className="icon-down-circled2 "></i>Download My CV</button> */}
        </div>
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">Personal Info</h3>
            <span className="line mb-5"></span>
            <ul className="mt40 info list-unstyled">
                <li><span>Birthdate</span> : 12/07/1981</li>
                <li><span>Email</span> : info@karmtec.com</li>
            </ul>
            <ul className="social-icons pt-3">
{/*                 <li className="social-item"><a className="social-link" href="#"><i className="ti-facebook" aria-hidden="true"></i></a></li>
                <li className="social-item"><a className="social-link" href="#"><i className="ti-twitter" aria-hidden="true"></i></a></li>
                <li className="social-item"><a className="social-link" href="#"><i className="ti-google" aria-hidden="true"></i></a></li>
                <li className="social-item"><a className="social-link" href="#"><i className="ti-instagram" aria-hidden="true"></i></a></li> */}
                <li className="social-item"><a className="social-link" href="https://www.gitlab.com/karmtec"><i className="ti-github" aria-hidden="true"></i></a></li>
            </ul>  
        </div>
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">My Expertise</h3>
            <span className="line mb-5"></span>
            <div className="row">
                <div className="col-1 text-danger pt-1"><i className="ti-widget icon-lg"></i></div>
                <div className="col-10 ml-auto mr-3">
                    <h6>Programming</h6>
                    <p className="subtitle">Python, C#, TypeScript, Node.js</p>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-1 text-danger pt-1"><i className="ti-paint-bucket icon-lg"></i></div>
                <div className="col-10 ml-auto mr-3">
                    <h6>Web Development</h6>
                    <p className="subtitle">React, CSS, ASP.net, HTML5</p>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-1 text-danger pt-1"><i className="ti-stats-up icon-lg"></i></div>
                <div className="col-10 ml-auto mr-3">
                    <h6>Information Technology</h6>
                    <p className="subtitle">Agile, Infrastructure, Cloud, DevOps, ITIL</p>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</div>
      )
}
}