
import React, { Component } from 'react'
import web1 from '../img/photobooth.jpg'



export default class Portfolio extends Component {
  render() {
    return (
        <section className="section bg-custom-gray" id="portfolio">
            <div className="container">
                <h1 className="mb-5"><span className="text-danger">My</span> Portfolio</h1>
                <div className="portfolio">
                    <div className="portfolio-container"> 
                        <div className="col-md-4 col-lg-4 web new">
                            <div className="portfolio-item">
                                <img src={web1} className="img-fluid" alt="" />
                                <div className="content-holder">
                                    <div className="text-holder">
                                        <a href="https://gitlab.com/karmtec/photobooth"><h6 className="title">PhotoBooth App</h6></a>
                                        <p className="subtitle">Desktop App to run SLR Enabled Photobooth</p>
                                    </div>
                                </div>   
                            </div>             
                        </div>

                    </div>               
                </div>  
            </div>            
        </section>
        )
    }
}