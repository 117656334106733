import React from 'react';
import './App.css';
import './themify-icons/css/themify-icons.css';

import Header from './components/header'
import NavBar from './components/navbar';
import Portfolio from './components/portfolio';
import About from './components/about';

function App() {
  return (
    <div className="App">
        <Header></Header>
        <NavBar></NavBar>
        <About></About>
        <Portfolio></Portfolio>

      </div>
  );
}


export default App;
