import React, { Component } from 'react'
import SocialLinks from './social_links'


export default class Header extends Component {
  render() {
    return (
        <header className="header" id="home">
        <div className="container">
            <SocialLinks></SocialLinks>
            <div className="header-content">
                <h4 className="header-subtitle" >Hello, I am</h4>
                <h1 className="header-title">Karim Rashid</h1>
                <h6 className="header-mono" >Leader | Developer | Builder | Father</h6>
            </div>
        </div>
    </header>
    )
  }
}