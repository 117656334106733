import React, { Component } from 'react'

export default class SocialLinks extends Component {
  render() {
    return (
      <ul className="social-icons pt-3">
                      <li className="social-item"><a className="social-link text-light" href="https://m.facebook.com/karim.rashid.520/"><i className="ti-facebook" aria-hidden="true"></i></a></li>
                      {/* <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-instagram" aria-hidden="true"></i></a></li>
                      <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-github" aria-hidden="true"></i></a></li> */}
      </ul>
    )
  }
}